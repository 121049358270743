import { getMomentObjectFromDate } from '@/util';
import moment from 'moment';

export default {
  computed: {
    dateFormat() {
      return this.$store.state.locale === 'hu' ? 'YYYY. MMM. DD.' : 'MMM DD, YYYY';
    },
    shortDateFormat() {
      return this.$store.state.locale === 'hu' ? 'YYYY. MMM. DD.' : 'MMM DD, YY';
    },
    timeFormat() {
      return this.$store.state.locale === 'hu' ? 'HH:mm' : 'hh:mm A';
    },
    longDateTimeFormat() {
      return this.$store.state.locale === 'hu' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD hh:mm a';
    },
    daysText() {
      return this.$store.state.locale === 'hu' ? 'nap' : 'days';
    },
  },

  methods: {
    sinceBetweenDate(from, to, format) {
      from = getMomentObjectFromDate(from);
      to = getMomentObjectFromDate(to);

      return `${from.format(format || this.dateFormat)} - ${to.format(format || this.dateFormat)}`;
    },
    getMomentObjectToInterval(date) {
      const year = parseInt(date.format('YYYY'), 10);
      const month = parseInt(date.format('MM'), 10) - 1; // month is zero based
      const day = parseInt(date.format('D'), 10);
      return moment([year, month, day]);
    },
    sinceDate(date, format) {
      date = getMomentObjectFromDate(date);

      return `${date.format(format || this.dateFormat)}`;
    },
    formatDate(date, format) {
      date = getMomentObjectFromDate(date);
      return date.format(format || this.dateFormat);
    },
    formatShortDate(date, format) {
      date = getMomentObjectFromDate(date);
      return date.format(format || this.shortDateFormat);
    },
    formatTime(date, format) {
      date = getMomentObjectFromDate(date);
      return date.format(format || this.timeFormat);
    },
    formatDateTime(date, format) {
      date = getMomentObjectFromDate(date);
      return date.format(format || `${this.dateFormat} [</br>] ${this.timeFormat}`);
    },
    timeUnitFormat(number) {
      const numberAsString = number.toString();
      if (numberAsString.length === 1) {
        return `0${numberAsString}`;
      }

      return numberAsString;
    },
    getDiffToGMT() {
      const offset = new Date().getTimezoneOffset();

      return `${offset >= 0 ? '-' : '+'}${Math.abs(offset / 60)}`;
    },
  },
};
