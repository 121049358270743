import { render, staticRenderFns } from "./InvoiceHistory.vue?vue&type=template&id=30abdc8d&scoped=true&lang=pug&"
import script from "./InvoiceHistory.vue?vue&type=script&lang=js&"
export * from "./InvoiceHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30abdc8d",
  null
  
)

export default component.exports