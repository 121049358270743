<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col
      om-heading.mt-1(h1) {{ $t('invoiceHistory') }}
  .row
    .col-12.brand-invoice-history-page.position-static
      loading-logo(v-if="loading")
      invoice-table(
        v-else
        :invoices="invoices"
        :invoiceCount="count"
        :pagination="pagination"
        @downloadInvoice="downloadInvoice($event)"
        @paginationChange="paginationChange($event)"
      )
</template>

<script>
  import InvoiceTable from '@/components/InvoiceTable.vue';
  import GET_INVOICES from '@/graphql/GetInvoices.gql';
  import { createNamespacedHelpers } from 'vuex';

  const { mapActions: paymentActions } = createNamespacedHelpers('payment');

  export default {
    components: {
      'invoice-table': InvoiceTable,
    },
    data() {
      return {
        loading: true,
        invoiceHistory: [],
        page: 1,
        limit: 30,
        sorting: { field: '', direction: '' },
        pagination: { page: 1, limit: 30 },
      };
    },
    computed: {
      invoices() {
        return (
          this.invoiceHistory?.invoices.slice(
            (this.page - 1) * this.limit,
            this.limit * this.page,
          ) || []
        );
      },
      count() {
        return this.invoiceHistory?.count || 0;
      },
    },
    async mounted() {
      const {
        data: { getInvoices },
      } = await this.$apollo.query({
        query: GET_INVOICES,
      });

      this.invoiceHistory = getInvoices;
      this.loading = false;
    },
    methods: {
      ...paymentActions(['downloadInvoice']),
      paginationChange(event) {
        this.page = parseInt(event.page, 10);
        this.limit = parseInt(event.limit, 10);
        this.pagination = event;
      },
    },
  };
</script>

<style scoped></style>
