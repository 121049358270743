<template lang="pug">
om-table(:items="invoices"
         :columns="columns"
         :allItemsCount="invoiceCount"
         :selectable="false"
         :pagination="pagination"
         @paginationChange="paginationChange($event)")
  template(slot="orderId" slot-scope="{rowData}")
    .td \#{{rowData.row.orderId}}
  template(slot="status" slot-scope="{rowData}")
    .td 
      .brand-btn-invoice-status {{rowData.row.status}}
  template(slot="dateCreated" slot-scope="{rowData}")
    .td {{ formatDate(rowData.row.dateCreated, dateFormat) }}
  template(slot="viewInvoice" slot-scope="{rowData}")
    popper(trigger="click" :options="{placement: 'top'}")
      .popper
        .brand-popper-content.brand-popper-content-invoice-history
          .row.brand-popper-content-invoice-history-row
            .col-4.pl-0 {{ $t('orderId') }}
            .col-8 \#{{ rowData.row.orderId }}
          .row.brand-popper-content-invoice-history-row
            .col-4.pl-0 {{ $t('dateCreated') }}
            .col-8 {{ formatDate(rowData.row.dateCreated, dateFormat) }}
          .row.brand-popper-content-invoice-history-row
            .col-4.pl-0 {{ $t('orderedPackage') }}
            .col-8 {{ rowData.row.details.product.name }}
          .row.brand-popper-content-invoice-history-row
            .col-4.pl-0 {{ $t('subTotal') }}
            .col-8 {{ rowData.row.details.totals.subtotal }}
          .row.brand-popper-content-invoice-history-row(v-if="rowData.row.details.totals.coupon")
            .col-4.pl-0 {{ $t('discount') }}
            .col-8 {{ rowData.row.details.totals.coupon }}
          .row.brand-popper-content-invoice-history-row(v-if="rowData.row.details.totals.tax")
            .col-4.pl-0 {{ $t('tax') }}
            .col-8 {{ rowData.row.details.totals.tax }}
          .row.brand-popper-content-invoice-history-row
            .col-4.pl-0 {{ $t('paymentFee')}}
            .col-8 {{ rowData.row.details.totals.payment }}
          .row.brand-popper-content-invoice-history-row
            .col-4.pl-0 {{ $t('total')}}
            .col-8 {{ rowData.row.details.totals.total }}
          .row.brand-popper-content-invoice-history-row
            .col-4.bb-0.pl-0 {{ $t('billingAddress') }}
            .col-8.bb-0(v-html="rowData.row.details.address + (rowData.row.details.vat_number ? `<br/>${rowData.row.details.vat_number}` : '')")

      button(type="button" slot="reference").brand-btn.brand-btn-link.brand-btn-view
        i(class="far fa-eye")
        span.ml-2 {{$t('view')}}
  template(slot="downloadInvoice" slot-scope="{rowData}" v-if="rowData.row.downloadable && rowData.row.types.includes('eszamla')")
    button(type="button" @click="$emit('downloadInvoice', { orderId: rowData.row.orderId, type: 'eszamla' })").brand-btn.brand-btn-link
      DownloadSvg
  template(slot="downloadProforma" slot-scope="{rowData}" v-if="rowData.row.downloadable && rowData.row.types.includes('proforma')")
    button(type="button" @click="$emit('downloadInvoice', { orderId: rowData.row.orderId, type: 'proforma' })").brand-btn.brand-btn-link
      DownloadSvg
</template>

<script>
  import dateFormat from '@/mixins/dateFormat';
  import DownloadSvg from '@/assets//admin/svg/settings/DownloadSvg';

  export default {
    components: { DownloadSvg },
    mixins: [dateFormat],

    props: {
      invoices: { type: Array, required: true },
      invoiceCount: { type: Number, required: true },
    },

    data() {
      return {
        pagination: { page: 1, limit: 30 },
        popoverInstance: null,
      };
    },

    computed: {
      columns() {
        const columns = [
          { header: this.$t('order'), key: 'orderId', sortable: false },
          { header: this.$t('status'), key: 'status', sortable: false },
          { header: this.$t('date'), key: 'dateCreated', sortable: false },
          { header: this.$t('price'), key: 'total', sortable: false },
          { header: this.$t('view'), key: 'viewInvoice', sortable: false },
          { header: this.$t('invoice'), key: 'downloadInvoice', sortable: false },
        ];

        if (this.invoices.find((invoice) => invoice.types.includes('proforma'))) {
          columns.push({ header: this.$t('proforma'), key: 'downloadProforma', sortable: false });
        }

        return columns;
      },
    },

    methods: {
      paginationChange(event) {
        this.pagination = event;
        this.$emit('paginationChange', event);
      },
    },
  };
</script>
